<template>
  <div>
    <q-form ref="editForm">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="건강검진 기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn v-show="editable && isOld" label="LBLREMOVE" icon="delete_forever" @btnClicked="remove" />
              <c-btn
                v-show="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="checkUp"
                :mappingType="saveType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="savePlan"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 병원 -->
              <c-select
                required
                :editable="editable"
                :disabled="isOld"
                :comboItems="comboItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="hospitalId"
                label="병원"
                v-model="checkUp.hospitalId"
              ></c-select>
              <!-- @datachange="datachange" -->
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                required
                :editable="editable"
                :disabled="checkUp.typeFlag === 'N' || isOld"
                codeGroupCd="CHECK_UP_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="checkupTypeCd"
                label="건강검진 종류"
                v-model="checkUp.checkupTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                required
                label="제목"
                name="checkupName"
                v-model="checkUp.checkupName"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant
                required
                type="edit"
                name="plantCd"
                v-model="checkUp.plantCd"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                required
                :range="true"
                label="건강검진 기간"
                name="checkupDateArray"
                v-model="checkUp.checkupDateArray"
              />
            </div>
          </template>
        </c-card>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "check-up-plan",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
      }),
    },
    checkUp: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        checkupStartDate: '',
        checkupEndDate: '',
        regUserId: '',
        chgUserId: '',

        // prototype
        hospitalId: '',
        hospitalLocation: '',
        typeFlag: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      searchParam: {
        userName: '',
        deptList: [],
        checkupTypeCd: null,
      },
      tempList: [],
      deptNames: '', // 부서 복수로 나타내기
      hazardNames: '', // 유해인자 복수로 나타내기
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      comboItems:[],
      getUrl: '',
      insertUrl: '',
      saveUrl: '',
      updateUrl: '',
      deleteUrl: '',
      targetUserUrl: '',
      isSave: false,
      saveType: 'POST',
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isOld() {
      return Boolean(this.popupParam.heaCheckupPlanId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.targetUserUrl = selectConfig.hea.checkup.plan.target.users.url;
      this.insertUrl = transactionConfig.hea.checkup.plan.insert.url;
      this.updateUrl = transactionConfig.hea.checkup.plan.update.url;
      this.deleteUrl = transactionConfig.hea.checkup.plan.delete.url;
      this.saveUrl = transactionConfig.hea.checkup.plan.insert.url

      this.setHospital();
    },
    setHospital() {
      this.$http.url = selectConfig.hea.hospital.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.comboItems.push({
            code: _item.hospitalId,
            codeName: _item.hospitalName,
            typeFlag: _item.typeFlag,
          })
        })
      },);
    },
    savePlan() {
      if (this.checkUp.heaCheckupPlanId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.checkUp.regUserId = this.$store.getters.user.userId
              this.checkUp.chgUserId = this.$store.getters.user.userId
              
              if (this.checkUp.checkupDateArray && this.checkUp.checkupDateArray.length > 0) {
                this.checkUp.checkupStartDate = this.checkUp.checkupDateArray[0];
                this.checkUp.checkupEndDate = this.checkUp.checkupDateArray[1];
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.checkUp.heaCheckupPlanId) {
        this.$emit('changeStatus', result.data)
      } else {
        this.$emit('getDetail')
      }
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까? 계획과 결과의 모든 정보가 삭제됩니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.checkUp.heaCheckupPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange() {
      if (this.checkUp.hospitalId === 'HHI0000007') {
        this.$set(this.checkUp, 'checkupTypeCd', 'CUTC000004');
      } else {
        let idx = this.$_.findIndex(this.comboItems, { code: this.checkUp.hospitalId });

        if (idx >= 0) {
          this.$set(this.checkUp, 'typeFlag', this.comboItems[idx].typeFlag);
          if (this.comboItems[idx].typeFlag === 'N') {
            this.$set(this.checkUp, 'checkupTypeCd', 'CUTC000006');
          } else {
            this.$set(this.checkUp, 'checkupTypeCd', null);
          }
        } else {
          this.$set(this.checkUp, 'typeFlag', 'Y');
          this.$set(this.checkUp, 'checkupTypeCd', null);
        }
      }
    }
  },
};
</script>
